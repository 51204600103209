<template>
  <b-row class="match-height">
    <b-col cols="12">
      <b-card :title="this.$route.meta.title">
        <b-alert
          v-height-fade
          dismissible
          v-model="showDismissibleAlert"
          fade
          class="mb-2"
          variant="danger"
          v-id="showDismissibleAlert == true"
        >
          <div class="alert-body">
            <span>{{ messages }}</span>
          </div>
        </b-alert>
        <ValidationObserver v-slot="{ handleSubmit }">
          <b-form @submit.prevent="handleSubmit(save)">
            <b-row>
              <b-col md="12">
                <ValidationProvider
                  name="Language"
                  rules="required"
                  v-slot="{ errors }"
                >
                  <b-form-group label="Language" label-for="mc-language">
                    <select class="form-control" v-model="model.languageCode">
                      <option
                        v-for="(i, index) in languages"
                        :key="index"
                        :value="i.code"
                      >
                        {{ i.name }}
                      </option>
                    </select>

                    <p class="fs-6 text-danger">
                      {{ errors[0] }}
                    </p>
                  </b-form-group>
                </ValidationProvider>
              </b-col>
              <b-col md="6">
                <ValidationProvider
                  name="Title"
                  rules="required"
                  v-slot="{ errors }"
                >
                  <b-form-group label="Title" label-for="mc-title">
                    <b-form-input
                      id="mc-title"
                      placeholder="Title"
                      v-model="model.title"
                    />

                    <p class="fs-6 text-danger">
                      {{ errors[0] }}
                    </p>
                  </b-form-group>
                </ValidationProvider>
              </b-col>
              <b-col md="6">
                <ValidationProvider
                  name="Subtitle"
                  rules="required"
                  v-slot="{ errors }"
                >
                  <b-form-group label="Subtitle" label-for="mc-subtitle">
                    <b-form-input
                      id="mc-subtitle"
                      placeholder="Subtitle"
                      v-model="model.subtitle"
                    />

                    <p class="fs-6 text-danger">
                      {{ errors[0] }}
                    </p>
                  </b-form-group>
                </ValidationProvider>
              </b-col>
            </b-row>

            <b-card-footer>
              <!-- submit and reset -->
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                type="submit"
                variant="primary"
                class="mr-1 float-right"
              >
                Save
              </b-button>
              <b-button
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                type="reset"
                class="mr-1 float-right"
                variant="outline-secondary"
                :to="{ name: 'content-intelektual' }"
              >
                Cancel
              </b-button>
            </b-card-footer>
          </b-form>
        </ValidationObserver>
      </b-card>
    </b-col>
  </b-row>
</template>

<style>
.mx-datepicker {
  width: 100% !important;
}

.ql-container {
  height: 200px !important;
}
</style>

<script>
import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormFile,
  BFormCheckbox,
  BForm,
  BButton,
  BCard,
  BCardFooter,
  BFormRadioGroup,
  BFormInvalidFeedback,
  BFormValidFeedback,
  BFormTextarea,
  BAlert,
} from "bootstrap-vue";
import vSelect from "vue-select";
import Ripple from "vue-ripple-directive";
import axios from "@axios";
import { heightFade } from "@core/directives/animations";

export default {
  components: {
    BCard,
    BCardFooter,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormFile,
    BFormCheckbox,
    BForm,
    BButton,
    BFormRadioGroup,
    vSelect,
    BFormInvalidFeedback,
    BFormValidFeedback,
    BFormTextarea,
    BAlert,
  },
  directives: {
    Ripple,
    "height-fade": heightFade,
  },
  data() {
    return {
      errors: {},
      showDismissibleAlert: false,
      config: {
        api: "content/company-list",
        redirect: "content-company",
        language: "languages",
      },
      model: {},
      messages: "",
      languages: [],
    };
  },
  mounted() {
    let _ = this;
    _.getLanguage();
    _.getData();
  },
  methods: {
    getLanguage() {
      let _ = this;
      axios.get(_.config.language).then((response) => {
        let _ = this;
        _.languages = response.data.rows;
      });
    },
    getData() {
      let _ = this;
      if (this.$route.meta.action == "edit") {
        axios
          .get(_.config.api + "/" + this.$route.params.id)
          .then((response) => {
            let _ = this;
            _.model = response.data;
            _.model.title = _.model.header.title;
            _.model.subtitle = _.model.header.subtitle;
          });
      }
    },
    save() {
      let _ = this;
      if (this.$route.meta.action == "store") {
        let data_post = {
          header: {
            title: _.model.title,
            subtitle: _.model.subtitle,
          },
          languageCode: _.model.languageCode,
        };
        axios
          .post(_.config.api, data_post)
          .then((res) => {
            _.$router.push({
              name: _.config.redirect,
              params: {
                event: "success",
                title: "Create Success",
                text: "Data has been saved",
              },
            });
          })
          .catch((e) => {
            let vm = this;
            vm.showDismissibleAlert = true;
            if (typeof e.response.data.message === "object") {
              vm.messages = e.response.data.message[0];
            } else {
              vm.messages = e.response.data.message;
            }
          });
      } else {
        _.model.id = _.$route.params.id;
        let data_post = {
          header: {
            title: _.model.title,
            subtitle: _.model.subtitle,
          },
          languageCode: _.model.languageCode,
        };
        axios
          .patch(_.config.api + "/" + _.$route.params.id, data_post)
          .then((res) => {
            _.$router.push({
              name: _.config.redirect,
              params: {
                event: "success",
                title: "Update Success",
                text: "Data has been Updated",
              },
            });
          })
          .catch((e) => {
            let vm = this;
            vm.showDismissibleAlert = true;
            if (typeof e.response.data.message === "object") {
              vm.messages = e.response.data.message[0];
            } else {
              vm.messages = e.response.data.message;
            }
          });
      }
    },
  },
  watch: {
    "model.code"(newValue) {
      this.model.code = newValue.toLowerCase();
    },
  },
};
</script>
